import { StyleSheet, View } from "react-native";
import { GlobalContextProvider } from "./components/GlobalContextProvider";
import Navigation from "./Navigation";
import * as Sentry from "sentry-expo";

Sentry.init({
  dsn: "https://9d6600611f354069b484b4ee663b7ced@o4504685919338496.ingest.sentry.io/4504685926285312",
  tracesSampleRate: 1.0,
  enableInExpoDevelopment: true,
  debug: true,
});

export default function App() {
  return (
    <View style={styles.container}>
      <GlobalContextProvider>
        <Navigation />
      </GlobalContextProvider>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#fff',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
});
