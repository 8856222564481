import { NativeStackScreenProps } from "@react-navigation/native-stack";
import * as React from "react";
import { Text, View, StyleSheet, TouchableOpacity } from "react-native";
import { HomeStackParamList } from "../types";

type HomeScreenProps = NativeStackScreenProps<HomeStackParamList, "HomeScreen">;

export default function HomeScreen({ navigation }: HomeScreenProps) {
  return (
    <View style={styles.screen}>
      <TouchableOpacity
        onPress={() => {
          navigation.navigate("HomeNext");
        }}>
        <View style={styles.buttonContainer}>
          <Text style={styles.textStyle}>START</Text>
          <Text style={styles.textStyle}>WORK</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    // flexDirection:"row",
    alignItems: "center",
    backgroundColor: "#D4D4D4",
    borderColor: "black",
    borderRadius: 35,
    borderWidth: 2,
    height: 120,
    justifyContent: "center",
    width: 150,
  },
  screen: {
    alignItems: "center",
    backgroundColor: "#F1F1F1",
    flex: 1,
    justifyContent: "center",
  },
  textStyle: {
    color: "black",
    fontFamily: "Inter",
    fontSize: 16,
  },
});
