import * as React from "react";
import { Text, View, StyleSheet, TouchableOpacity } from "react-native";

export default function SettingsScreen() {
  return (
    <View style={styles.screen}>
      <TouchableOpacity style={styles.buttonContainer} onPress={() => {}}>
        <Text>Buton 1</Text>
      </TouchableOpacity>
      <TouchableOpacity style={styles.buttonContainer} onPress={() => {}}>
        <Text>Buton 2</Text>
      </TouchableOpacity>
      <TouchableOpacity style={styles.buttonContainer} onPress={() => {}}>
        <Text>Buton 3</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    alignItems: "center",
    backgroundColor: "white",
    borderColor: "black",
    borderRadius: 20,
    borderWidth: 2,
    height: 46,
    justifyContent: "center",
    marginTop: 30,
    width: 240,
  },
  screen: {
    // justifyContent: 'center',
    alignItems: "center",
    flex: 1,
  },
});
