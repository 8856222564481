import { FirebaseApp, initializeApp } from "firebase/app";
import { User } from "firebase/auth";
import { Firestore, getFirestore } from "firebase/firestore";
import { createContext, useEffect, useState, useContext } from "react";
import { firebaseConfig } from "../firebase";

interface GlobalContextProviderType {
  app: FirebaseApp;
  user: User | null;
  db: Firestore | null;

  login: (user: User) => void;
}

const defaultContext: GlobalContextProviderType = {
  app: initializeApp(firebaseConfig),
  user: null,
  db: null,

  login: () => {},
};

const GlobalContext = createContext<GlobalContextProviderType>(defaultContext);

interface GlobalContextProviderProps {
  children: JSX.Element | JSX.Element[];
}

export const GlobalContextProvider = ({
  children,
}: GlobalContextProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [context, setContext] = useState<GlobalContextProviderType>({
    ...defaultContext,

    login: setUser,
  });

  useEffect(() => {
    if (user !== context.user) {
      setContext((prev) => ({ ...prev, user, db: getFirestore(prev.app) }));
    }
  }, [user, context.user]);

  return (
    <GlobalContext.Provider value={context}>{children}</GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
export const useUser = () => useGlobalContext().user;
export const useDb = () => useGlobalContext().db;
