// import { StyleSheet } from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import HomeScreen from "../screens/HomeScreen";
import SettingsScreen from "../screens/Settings";
import HomeNext from "../screens/HomeNext";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useEffect, useState } from "react";
import { useDb, useUser } from "../components/GlobalContextProvider";
import { doc, getDoc } from "firebase/firestore";
import ManagerScreen from "../screens/ManagerScreen";
import {
  BottomTabParamList,
  HomeStackParamList,
  ManagmentStackParamList,
} from "../types";
import { Ionicons } from "@expo/vector-icons";

const Tab = createBottomTabNavigator<BottomTabParamList>();

export default function BottomTabNavigator() {
  const db = useDb();
  const user = useUser();
  const [isManager, setIsManager] = useState(false);

  useEffect(() => {
    if (db === null || user == null) return;
    let active = true;
    const ref = doc(db, "/managers", "4wV8gVHsMqbztX3NyM5duM9LvdF2");
    getDoc(ref)
      .then((doc) => {
        if (doc.exists() && active) {
          setIsManager(true);
        }
      })
      .catch(() => {});

    return () => {
      active = false;
    };
  }, [db, user]);

  return (
    <Tab.Navigator>
      <Tab.Screen
        name="Home"
        component={HomeStackNav}
        options={{
          headerShown: false,
          tabBarIcon: ({ focused }) => {
            return (
              <Ionicons
                name={focused ? "home" : "home-outline"}
                size={16}
                color="black"
                style={{ marginTop: 0 }}
              />
            );
          },
        }}
      />

      {isManager && (
        <Tab.Screen
          name="Management"
          component={ManagerStackNav}
          options={{
            title: "Management",
            headerStyle: { backgroundColor: "#f4511e" },
            headerTintColor: "#fff",
            headerTitleStyle: { fontWeight: "bold" },
          }}
        />
      )}
      <Tab.Screen
        name="Settings"
        component={SettingsScreen}
        options={{
          headerStyle: { backgroundColor: "#f4511e" },
          headerTintColor: "#fff",
          headerTitleAlign: "center",
          tabBarIcon: ({ focused }) => {
            return (
              <Ionicons
                name={focused ? "settings" : "settings-outline"}
                size={16}
                color={"black"}
                style={{ marginTop: 2 }}
              />
            );
          },
        }}
      />
    </Tab.Navigator>
  );
}

const HomeStack = createNativeStackNavigator<HomeStackParamList>();

function HomeStackNav() {
  return (
    <HomeStack.Navigator initialRouteName="HomeScreen">
      <HomeStack.Screen
        name="HomeScreen"
        component={HomeScreen}
        options={{
          title: "Home Screen",
          headerStyle: { backgroundColor: "#f4511e" },
          headerTintColor: "#fff",
          headerTitleStyle: { fontWeight: "bold" },
          headerTitleAlign: "center",
        }}
      />
      <HomeStack.Screen
        name="HomeNext"
        component={HomeNext}
        options={{
          title: "Home Next",
          headerStyle: { backgroundColor: "#f4511e" },
          headerTintColor: "#fff",
          headerTitleStyle: { fontWeight: "bold" },
          headerTitleAlign: "center",
        }}
      />
    </HomeStack.Navigator>
  );
}

const ManagerStack = createNativeStackNavigator<ManagmentStackParamList>();

function ManagerStackNav() {
  return (
    <ManagerStack.Navigator initialRouteName="ManagerScreen">
      <ManagerStack.Screen name="ManagerScreen" component={ManagerScreen} />
    </ManagerStack.Navigator>
  );
}

/*
const styles = StyleSheet.create({
  screen: {
    // flex: 1,
    // justifyContent: 'center',
    // alignItems: 'center',
  },
});
*/

/* 
function HomeScreen(){
const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 700;
  React.useEffect(() => {
   const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  if (width > breakpoint) {
    return (
      <div>
        <h3>Component 1</h3>
        <p>Current width is {width} px</p>
      </div>
    );
  }
  return (
    <div>
      <h3>Component 2</h3>
      <p>Current width is {width} px</p>
    </div>
  );


  ((focused,size:{size:24}) => {
            focused ?  <Image style={{width:size,height:size}} 
            source={AA}/>: <Image style={{width:size,height:size}} source={AA}/>})}}
}*/
