import React from "react";
import { StackScreenProps } from "@react-navigation/stack";
import { RootStackParamList } from "../types";
import { Button, View, Text } from "react-native";

export default function NotFoundScreen({
  navigation,
}: StackScreenProps<RootStackParamList, "NotFound">) {
  return (
    <View>
      <Text>This screen doesn&apos;t exist</Text>
      <Button
        onPress={() => navigation.replace("Root")}
        title="Go to home screen"
      />
    </View>
  );
}
