import { NativeStackScreenProps } from "@react-navigation/native-stack";
import * as React from "react";
import { Text, View, StyleSheet } from "react-native";
import { useUser } from "../components/GlobalContextProvider";
import { ManagmentStackParamList } from "../types";

type ManagerScreenProps = NativeStackScreenProps<
  ManagmentStackParamList,
  "ManagerScreen"
>;

export default function ManagerScreen({ navigation }: ManagerScreenProps) {
  const user = useUser();
  return (
    <View style={styles.screen}>
      <Text>Hello manager: {user?.displayName}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  screen: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
});
