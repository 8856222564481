export const firebaseConfig = {
  apiKey: "AIzaSyANCG5BNigBC7FSFSvFj9FCGtyyhVzmnYo",
  authDomain: "atnw-f7ba5.firebaseapp.com",
  projectId: "atnw-f7ba5",
  storageBucket: "atnw-f7ba5.appspot.com",
  messagingSenderId: "68694950752",
  appId: "1:68694950752:web:3e8154ae5ceb8db79e9405",
  measurementId: "G-0MK2GC42J8",
};

export const firebaseAuthConfig = {
  clientId:
    "68694950752-ogrtdb32rf7t6f91h9livo8s6ricnpgq.apps.googleusercontent.com",
  webClientId:
    "68694950752-ogrtdb32rf7t6f91h9livo8s6ricnpgq.apps.googleusercontent.com",
  expoClientId:
    "68694950752-ogrtdb32rf7t6f91h9livo8s6ricnpgq.apps.googleusercontent.com",
};
