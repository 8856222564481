import {
  getAuth,
  GoogleAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import * as Google from "expo-auth-session/providers/google";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  SafeAreaView,
} from "react-native";
import React from "react";
import GG from "../icon/GG.png";
import * as WebBrowser from "expo-web-browser";
import { firebaseAuthConfig } from "../firebase";
import { useGlobalContext } from "../components/GlobalContextProvider";

WebBrowser.maybeCompleteAuthSession();

export default function LoginScreen() {
  const [request, response, promptAsync] =
    Google.useIdTokenAuthRequest(firebaseAuthConfig);

  const globalContext = useGlobalContext();

  React.useEffect(() => {
    let active = true;
    if (response?.type === "success") {
      // eslint
      const auth = getAuth(globalContext.app);
      const credential = GoogleAuthProvider.credential(
        response.params.id_token,
      );
      signInWithCredential(auth, credential).then((credential) => {
        if (!active) return;
        globalContext.login(credential.user);
      });
    }
    return () => {
      active = false;
    };
  }, [response, globalContext.login, globalContext]);

  return (
    <View style={styles.container}>
      <SafeAreaView>
        <Text style={styles.welcomeText}>Welcome</Text>
        <View style={{ marginTop: 250, alignItems: "center" }}>
          <TouchableOpacity
            disabled={!request}
            onPress={() => {
              promptAsync();
            }}>
            <View style={styles.buttonContainer}>
              <Image source={GG} style={styles.buttonImageStyle} />
              <Text style={styles.buttonText}>Sing In with Google</Text>
            </View>
          </TouchableOpacity>
          <Text style={{ marginTop: 10 }}>
            Upon login you accept{" "}
            <TouchableOpacity onPress={() => {}}>
              <Text style={{ textDecorationLine: "underline", color: "blue" }}>
                Terms And Conditons
              </Text>
            </TouchableOpacity>
          </Text>
        </View>
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    alignItems: "center",
    backgroundColor: "white",
    borderColor: "black",
    borderRadius: 20,
    borderWidth: 2,
    flexDirection: "row",
    height: 46,
    justifyContent: "center",
    width: 240,
  },
  buttonImageStyle: {
    alignItems: "center",
    height: 24,
    margin: 5,
    marginLeft: 0,
    padding: 10,
    resizeMode: "stretch",
    width: 24,
  },
  buttonText: {
    fontSize: 20,
  },
  container: {
    height: "99%",
    width: "100%",
    // width:1535,
    // height:789,
    // backgroundColor:'yellow',
    // flex:1,
    // alignItems: "center",
    // justifyContent: "center",
  },
  welcomeText: {
    fontSize: 30,
    fontWeight: "bold",
    marginTop: 120,
    textAlign: "center",
  },
});
